export const breakpoints = ["599px", "600px", "992px", "1200px"];

export const screens = {
  sm: breakpoints[0],
  md: breakpoints[1],
  lg: breakpoints[2],
  xl: breakpoints[3],
  print: { raw: "print" },
};

export const colors = {
  transparent: "transparent",
  current: "currentColor",
  darkDim: "#1616169f",
  lightDim: "#ffffff9f",
  black: "#080808",
  dark: "#161616",
  gray: "#818181",
  white: "#ffffff",
  gold: "#ffd700",
  darkGold: "#bda000",
  silver: "#cfcfcf",
  lunar: "#B0BEC5",
  red: "#7C0D0E",
  navy: "#001F3F",
  vibrant: "#7561F0",
  error: "#FF4444",
  success: "#44ff44",
  lightLink: "#fff1a0",
  darkLink: "#bda000",
};

export const fonts = {
  caption: "Archivo",
  black: "Archivo-Black",
  title: "Playfair-Display",
  body: "Poppins, sans-serif"
}

export const theme = {
  colors,
  font: {
    family: fonts,
    size: {
      normal: 'medium',
      xxs: 'xx-small',
      xs: 'x-small',
      sm: 'small',
      lg: 'large',
      xl: 'x-large',
      xxl: 'xx-large',
      sm2: 'smaller',
      lg2: 'larger',
    },
  },
  breakpoints,
  screens,
  width: {
    none: 'none',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      xl2: '42rem',
      xl3: '48rem',
      xl4: '56rem',
      xl5: '64rem',
      xl6: '72rem',
      xl7: '80rem',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      prose: '65ch',
  },
  height: {
    none: 'none',
      full: '100%',
      screen: '100vh',
      svh: '100svh',
      lvh: '100lvh',
      dvh: '100dvh',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
  }
};
