import { IFile, IFileList } from "../@types/global"
import { StorageImage } from "@aws-amplify/ui-react-storage"
import Box, { Break, Image } from "../styles/Box"
import { DownloadB, Trash } from "../styles/icons"
import { List, Item, Title, Button } from "../styles/Text"
import { theme } from "../styles/theme"
import { fileExtension, fileName } from "../utils/manageFiles"

const FileList: React.FC<IFileList> = ({ files, onDelete, onDownload}) => {
  const imageTypes = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".svg"];
  const audioTypes = [".aif", ".wav", ".aac", ".flac", ".alac", ".mp3"];
  const videoTypes = [".mov", ".mp4", ".avi"];
  const docTypes = [".docx", ".txt", ".rtf", ".pdf", ".xlsx", ".numbers"];

    return (
        <List display={"flex"} flex={1} flexWrap={"wrap"}>
              {files.length === 0 ? (
                <Item>
                  <Title>Empty</Title>
                </Item>
              ) : (
                files.map((file) => (
                  <Item
                    key={file.path}
                    width={[1, 1, 1, 1 / 2.2]}
                    margin={"1rem"}
                    backgroundColor={theme.colors.dark}
                  >
                    <Box padding={["1rem", "1rem", "3rem"]}>
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        width={1}
                        justifyContent={"center"}
                      >
                        <Button
                          width={["4rem", "5rem", "4rem", "3.5rem"]}
                          onClick={() => onDownload(file.path)}
                          color={theme.colors.white}
                        >
                          <DownloadB
                            color={theme.colors.white}
                            colorOnHover={theme.colors.gold}
                          />
                        </Button>
                        <Button
                          width={["4rem", "5rem", "4rem", "3.5rem"]}
                          onClick={() => onDelete(file.path)}
                          color={theme.colors.white}
                        >
                          <Trash
                            color={theme.colors.white}
                            colorOnHover={theme.colors.gold}
                          />
                        </Button>
                      </Box>
                      <Break />
                      <Box margin={"1rem auto"} width={1}>
                        {imageTypes.includes(
                          fileExtension(fileName(file.path))
                        ) && (
                          <StorageImage
                            path={file.path}
                            alt={`A thumbnail of the image file.`}
                          />
                        )}
                        {audioTypes.includes(
                          fileExtension(fileName(file.path))
                        ) && (
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={
                              "https://alkenox-media.s3.amazonaws.com/images/audio-default.jpeg"
                            }
                            alt={`A default image file.`}
                          />
                        )}
                        {videoTypes.includes(
                          fileExtension(fileName(file.path))
                        ) && (
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={
                              "https://alkenox-media.s3.amazonaws.com/images/video-default.jpeg"
                            }
                            alt={`A default image file.`}
                          />
                        )}
                        {docTypes.includes(
                          fileExtension(fileName(file.path))
                        ) && (
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={
                              "https://alkenox-media.s3.amazonaws.com/images/document-default.jpeg"
                            }
                            alt={`A default image file.`}
                          />
                        )}
                        {fileExtension(fileName(file.path)) === ".zip" && (
                          <Image
                            width={"100%"}
                            height={"100%"}
                            src={
                              "https://alkenox-media.s3.amazonaws.com/images/zipped-default.jpeg"
                            }
                            alt={`A default image file.`}
                          />
                        )}
                      </Box>
                      <Box>
                        <Title textAlign={"center"} color={theme.colors.white}>
                          {fileName(file.path)}
                        </Title>
                      </Box>
                    </Box>
                  </Item>
                ))
              )}
            </List>
    )
}

export default FileList;