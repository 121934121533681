import styled from "styled-components";
import Box from "../styles/Box";
import Text from '../styles/Text'
import { theme } from "../styles/theme";

const Bar = styled(Box)`
  min-height: 6vh;
  max-width: 100vw;
  background-image: linear-gradient(
    260deg,
    ${(props) => props.theme.colors.dark} 0%,
    ${(props) => props.theme.colors.red} 10%,
    ${(props) => props.theme.colors.dark} 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const Footer = () => {
  return (
      <Bar marginTop={'5rem'} display={'flex'} justifyContent={'center'} justifyItems={'center'}>
        <Text marginY={'auto'} color={theme.colors.white} textAlign={'center'}>Copyright &copy; 2024 LeeVan Enterprises, LLC. All rights reserved.</Text>
    </Bar>
  );
}
export default Footer;