import React from 'react';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import config from './amplifyconfiguration.json';
import Layout from './components/Layout';
import Routes from './components/Routes';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import { Authenticator } from '@aws-amplify/ui-react';
 
Amplify.configure(config);
cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

const App: React.FC = () => {

  return (
    <Authenticator.Provider>
      <Layout>
        <Routes />
      </Layout>
    </Authenticator.Provider>
    
  );
};

export default App;
