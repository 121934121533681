import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

interface IProps {
  dark: boolean;
  link: boolean;
}

const GlobalStyles = createGlobalStyle`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  *[hidden] {
    display: none;
  }

  body {
    font-family: ${theme.font.family.body};
    line-height: 1.5;
    font-size: 1rem;
    background-color: ${theme.colors.white};
    color: ${theme.colors.dark};
  }

  h1 {
    font-size: 3rem;
    font-weight: bold;
    text-decoration: underline;
    font-family: ${theme.font.family.title};
  }

  h2 {
    font-size: 2rem;
    font-weight: bold;
    text-decoration: underline;
    font-family: ${theme.font.family.title};
  }

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: ${theme.font.family.black};
  }

  h4 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: ${theme.font.family.black};
  }

  h5 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: ${theme.font.family.caption};
  }

  h6 {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: ${theme.font.family.caption};
  }

  caption {
    font-size: 0.875rem;
    font-family: ${theme.font.family.caption};
  }

  a {
    color: ${theme.colors.dark};
    &:hover {
      color: ${theme.colors.gold};
      cursor: pointer;
    }
  }
`;

export default GlobalStyles;
