import React, { useState } from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import { Button } from '../styles/Text';
import Box, { Break } from '../styles/Box';

const TabButton = styled(Button)<{isActive: boolean}>`
  outline: none;
  color: ${props => props.isActive ? theme.colors.vibrant : theme.colors.black};
  text-shadow: 0 0 .125rem ${theme.colors.dark};
  &:focus,
  &:focus-visible {
    border: none; /* Remove the focus border */
    outline: none; /* Remove the focus outline */
  }
  &:hover {
    color: ${props => props.theme.colors.vibrant};
  }
`;

TabButton.defaultProps = {
  backgroundColor: theme.colors.transparent,
  fontSize: theme.font.size.lg,
  padding: `12px 16px`,
  display: `inline-block`,
  borderRadius: `.5em`,
  border: `none;`,
}

type TabProps = {
  label: string;
  children: React.ReactNode;
};

export const Tab: React.FC<TabProps> = ({ children }) => {
  return <>{children}</>;
};

type TabsProps = {
  children: React.ReactElement<TabProps>[];
};

const TabsContainer = styled.div`
  width: 100%;
`;

const Tabs: React.FC<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <TabsContainer>
      <Box display={'flex'}>
        {children.map((tab, index) => (
          <TabButton
            key={index}
            isActive={activeTab === index}
            onClick={() => handleTabClick(index)}
          >
            {tab.props.label}
          </TabButton>
        ))}
      </Box>
      <Break />
      <Box marginTop={'1rem'}>{children[activeTab]}</Box>
    </TabsContainer>
  );
};

export default Tabs;
