import styled from "styled-components";
import { Link as Li, NavLink as Nav } from "react-router-dom";
import {
  space,
  layout,
  color,
  typography,
  flexbox,
  grid,
  background,
  border,
  position,
} from "styled-system";
import { StyledSystemTypes } from "../@types/global";
import { theme } from "./theme";

const Button = styled.button<StyledSystemTypes>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    &:hover {
      color: ${(props) => props.theme.colors.gold};
      cursor: pointer;
    }
`;

const TextButton = styled.p<StyledSystemTypes>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
    &:hover {
      color: ${(props) => props.theme.colors.gold};
      cursor: pointer;
    }
`;

Button.defaultProps = {
    backgroundColor: 'transparent',
    padding: `0.5rem 1rem`,
    border: `none`,
    borderRadius: `0.5rem`,
    width: `10rem`,
    margin: `0 auto`,
    fontSize: `1rem`,
}

const Anchor = styled.a<StyledSystemTypes & {dark?: boolean, underline?: boolean}>`
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.dark};
  text-decoration: ${props => !props.underline ? "none" : "underline"};
  &:hover {
    color: ${(props) => props.theme.colors.gold};
    cursor: pointer;
  }
`;

Anchor.defaultProps = {}

const Link = styled(Li)<StyledSystemTypes & {dark?: boolean, underline?: boolean}>`
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.dark};
  text-decoration: ${props => !props.underline ? "none" : "underline"};
  &:hover {
    color: ${(props) => props.theme.colors.gold};
    cursor: pointer;
  }
`;

Link.defaultProps = {}

const NavLink = styled(Nav)<StyledSystemTypes & {dark?: boolean, underline?: boolean}>`
  ${space}
  ${layout}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  color: ${props => props.dark ? props.theme.colors.white : props.theme.colors.dark};
  text-decoration: ${props => !props.underline ? "none" : "underline"};
  &:hover {
    color: ${(props) => props.theme.colors.gold};
    cursor: pointer;
  }
`;

NavLink.defaultProps = {}

const Heading = styled.h1<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  text-decoration: underline;
`;

Heading.defaultProps = {
  fontSize: `3rem`,
  fontWeight: `bold`,
  fontFamily: `${theme.font.family.title}`,
}

const SubHeading = styled.h2<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  text-decoration: underline;
`;

SubHeading.defaultProps = {
  fontSize: `2rem`,
  fontWeight: `bold`,
  fontFamily: `${theme.font.family.title}`,
}

const Title = styled.h3<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
`;

Title.defaultProps = {
  fontSize: `1.5rem`,
  fontWeight: `bold`,
  fontFamily: `${theme.font.family.black}`,
}

const List = styled.ul<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
  list-style-type: none;
`;

List.defaultProps = {
    padding: 0,
    margin: 0,
}

const Item = styled.li<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
`;

Item.defaultProps = {
  fontFamily: `${theme.font.family.body}`,
  lineHeight: `1.5`,
  fontSize: `1rem`,
  color: `${theme.colors.dark}`,
}

const Text = styled.p<StyledSystemTypes>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${flexbox}
  ${grid}
  ${background}
  ${border}
  ${position}
`;

Text.defaultProps = {
  fontFamily: `${theme.font.family.body}`,
  lineHeight: `1.5`,
  fontSize: `1rem`,
  color: `${theme.colors.dark}`,
}

export default Text;
export {
    Button,
    TextButton,
    Link,
    NavLink,
    Anchor,
    Heading,
    SubHeading,
    Title,
    Text,
    List,
    Item
}
