import { useAuthenticator } from "@aws-amplify/ui-react";
import React, { useState } from "react";
import styled from "styled-components";
import Box from "../styles/Box";
import AlkenoxLogo from "../styles/Alkenox";
import { Hamburger } from "../styles/icons";
import { theme } from "../styles/theme";

const Bar = styled(Box)`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  min-height: 4rem;
  max-width: 100vw;
  background-image: linear-gradient(
    260deg,
    ${(props) => props.theme.colors.dark} 0%,
    ${(props) => props.theme.colors.red} 10%,
    ${(props) => props.theme.colors.dark} 100%
  );
  border: 1px solid rgba(0, 0, 0, 0.2);
  @media (min-width: ${(props) => props.theme.screens.md}) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0;
    align-items: center;
  }
`;

const MainNav = styled.ul<{ display: string }>`
  list-style-type: none;
  display: ${(props) => props.display};
  flex-direction: column;
  margin: 3rem 0;
  @media (min-width: ${(props) => props.theme.screens.md}) {
    position: absolute;
    right: 0;
    display: flex;
    margin-right: 30px;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const NavLi = styled.li`
  text-align: center;
  margin: 0.25rem auto;
`;

const NavLink = styled.a`
  font-size: 1.25rem;
  color: ${(props) => props.theme.colors.white};
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  &:hover {
    text-shadow: 0 0 0.185rem ${(props) => props.theme.colors.dark};
  }
  @media (min-width: ${(props) => props.theme.screens.md}) {
    margin: 0px 0.35rem;
    font-size: 1.125rem;
  }
`;

const LogoLi = styled(NavLink)`
  display: inline-block;
  font-size: 1.25rem;
  margin-top: 0.1rem;
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.silver};
  text-shadow: 0 0 0.125rem ${(props) => props.theme.colors.dark};
  @media (min-width: ${(props) => props.theme.screens.md}) {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const NavLogo = styled(Box)`
  transform: translateY(15%);
`;

const NavBarToggle = styled(Box)`
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  font-size: 24px;
`;

const Navbar: React.FC = () => {
  const { signOut, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const onSignOut = () => {
    signOut();
    window.open("https://www.alkenox.com/", "__blank");
  };

  return (
    <Bar>
      <NavBarToggle onClick={toggleMenu}>
        <Hamburger color={theme.colors.white} colorOnHover={theme.colors.gold} />
      </NavBarToggle>
      <NavLogo
        width={[0.35, 0.35, 0.25, 0.175]}
        position={"absolute"}
        left={0}
      >
        <a href={'https://www.alkenox.com'} target={"__blank"}>
          <Box width={1}><AlkenoxLogo /></Box>
        </a>
      </NavLogo>
      <MainNav display={menuOpen ? "flex" : "none"}>
        {authStatus === "authenticated" && (
          <>
            <NavLi>
              <NavLink href={"/"}>files</NavLink>
            </NavLi>
            <NavLi>
              <NavLink href={"/contact"}>contact us</NavLink>
            </NavLi>
            <NavLi>
              <NavLink onClick={onSignOut}>logout</NavLink>
            </NavLi>
          </>
        )}
      </MainNav>
    </Bar>
  );
};

export default Navbar;
