import { useAuthenticator } from "@aws-amplify/ui-react";
import Box, { Image } from "../styles/Box";
import Text, { Button, SubHeading } from "../styles/Text";
import { useTheme } from "styled-components";
import { theme } from "../styles/theme";

export const components = {
  Header() {
    return (
      <Box display='flex' justifyContent="center">
        <Image
        width={'25vw'}
        margin={'4rem auto'}
          alt="Alkenox™️ logo"
          src="https://alkenox-media.s3.amazonaws.com/images/alkenox_emblem.svg"
        />
      </Box>
    );
  },

  Footer() {
    return (
      <Box marginY={'1rem'}>
        <Text textAlign={'center'}>&copy; All Rights Reserved</Text>
      </Box>
    );
  },

  

  SignIn: {
    Header() {
      return <SubHeading textAlign={'center'}>Sign In</SubHeading>;
    },
    Footer() {
      const { toForgotPassword } = useAuthenticator();

      return (
        <Box display={'flex'} justifyContent={'center'}>
          <Button onClick={toForgotPassword}>Reset Password</Button>
        </Box>
      );
    },
  },

  SignUp: {
    Header() {
      return <SubHeading textAlign="center">New Account</SubHeading>;
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <Box>
          <Button backgroundColor={theme.colors.vibrant} onClick={toSignIn}>Back to Sign In</Button>
        </Box>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      return <SubHeading textAlign="center">Enter Information:</SubHeading>;
    },
    Footer() {
      return <Text textAlign="center">Footer Information</Text>;
    },
  },
  SetupTotp: {
    Header() {
      return <SubHeading textAlign="center">Enter Information:</SubHeading>;
    },
    Footer() {
      return <Text textAlign="center">Footer Information</Text>;
    },
  },
  ConfirmSignIn: {
    Header() {
      return <SubHeading textAlign="center">Enter Information:</SubHeading>;
    },
    Footer() {
      return <Text textAlign="center">Footer Information</Text>;
    },
  },
  ForgotPassword: {
    Header() {
      return <SubHeading textAlign="center">Enter Information:</SubHeading>;
    },
    Footer() {
      return <Text textAlign="center">Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      return <SubHeading textAlign="center">Enter Information:</SubHeading>;
    },
    Footer() {
      return <Text textAlign="center">Footer Information</Text>;
    },
  },
};

export const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
    },
  },
  signUp: {
    password: {
      label: "Password:",
      placeholder: "Enter your Password:",
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: "Confirm Password:",
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  forgotPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  setupTotp: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};
