import { useState } from 'react';
import React from 'react';
import styled from "styled-components";
import { space, layout, color, typography, flexbox, grid, background, border, position } from "styled-system";
import { StyledSystemFormProps, StyledSystemInputProps, StyledSystemTypes } from "../@types/global";

const Field = styled.div<Partial<StyledSystemInputProps>>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
`;

Field.defaultProps = {
    marginBottom: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
}

const Line = styled.input<Partial<StyledSystemInputProps>>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
`;

Line.defaultProps = {
    borderRadius: '0.3rem',
    height: '2.5rem',
    padding: '0.5rem',
    marginY: '0.5rem',
}

const Textarea = styled.textarea<Partial<StyledSystemInputProps>>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
`;

Textarea.defaultProps = {
    borderRadius: '0.3rem',
    padding: '0.5rem',
    marginY: '0.5rem',
    rows: 6,
}

const Checkbox = styled.input<Partial<StyledSystemInputProps>>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
`;

Checkbox.defaultProps = {
    borderRadius: '0.3rem',
    height: '1rem',
    padding: '0.5rem',
    marginRight: '0.75rem',
}

const Form = styled.form<Partial<StyledSystemFormProps>>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
`;

Form.defaultProps = {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap'
}

// Define the prop types including the checkbox prop
interface ILabelProps extends StyledSystemTypes {
    checkbox?: boolean;
    children: React.ReactNode;
  }
  
  // Define the Label component with the specified props
  const StyledLabel = styled.label<ILabelProps>`
    ${space}
    ${layout}
    ${color}
    ${typography}
    ${flexbox}
    ${grid}
    ${background}
    ${border}
    ${position}
  `;
  
  // Define the defaultProps object
  const defaultProps: Partial<ILabelProps> = {
    display: 'flex',
    alignItems: 'center'
  };
  
  // Conditionally set flexWrap and flexDirection based on props
  const generateDefaultProps = (props: ILabelProps) => {
    const newProps = { ...defaultProps };
  
    if (props.checkbox) {
      newProps.flexWrap = ['wrap', 'wrap-reverse', 'nowrap'];
      newProps.flexDirection = ['column', 'row'];
    }
  
    return newProps;
  };
  
  const Label: React.FC<ILabelProps> = (props) => {
    React.useEffect(() => {
      const newProps = generateDefaultProps(props);
      // Log the new props for debugging
      console.log(newProps);
    }, [props.checkbox]); // Trigger useEffect when checkbox prop changes
  
    return <StyledLabel {...props} />;
  };
  
  // Assign the defaultProps to the Label component
  Label.defaultProps = defaultProps;

interface FormValues {
  [key: string]: any;
}

interface ValidationRules {
  [key: string]: (value: any) => string | undefined;
}

interface FormErrors {
  [key: string]: string | undefined;
}

const useForm = (initialValues: FormValues, validationRules: ValidationRules) => {
  const [values, setValues] = useState<FormValues>(initialValues);
  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? !values[name] : value;
    setValues({
      ...values,
      [name]: newValue,
    });
    errors[name] = "";
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name } = e.target;
    const errorMessage = validationRules[name](values[name]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMessage,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>, onSubmit: (values: FormValues) => void, onSubmitError?: (errors: FormErrors) => void) => {
    e.preventDefault();
    const validationErrors: FormErrors = {};
    Object.keys(validationRules).forEach((fieldName) => {
      const errorMessage = validationRules[fieldName](values[fieldName]);
      if (errorMessage) {
        validationErrors[fieldName] = errorMessage;
      }
    });
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      // You can do something with the validated form values here, like submitting them
      onSubmit(values)
      console.log('Form submitted:', values);
    } else {
      onSubmitError && onSubmitError(errors)
      console.log('Form has validation errors:', validationErrors);
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};

export default useForm;

export {
  Field,
  Line,
  Textarea,
  Checkbox,
  Label,
  Form
};
