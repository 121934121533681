import Footer from "./Footer";
import { ThemeProvider } from "styled-components";
import { theme } from "../styles/theme";
import GlobalStyles from "../styles/globalStyles";
import Navbar from "./Navbar";
import Box from "../styles/Box";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { components } from "./Auth";

interface IProps {
  children: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }: IProps): JSX.Element => {
  const { route } = useAuthenticator(context => [context.route]);
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={"Alkenox™️ offers media and entertainment services that empower creators across diverse fields, transforming their raw concepts into immersive and captivating experiences."} />
        <title>Alkenox&trade;: Member's Area</title>
      </head>
      <body>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Navbar />
          <Box height={'87vh'}>
          {route === 'authenticated' ? <Box marginTop={'4rem'}>{children}</Box> : <Authenticator hideSignUp signUpAttributes={['name', 'email']} components={components} />}
          </Box>
          <Footer />
        </ThemeProvider>
      </body>
    </html>
  );
};

export default Layout;
