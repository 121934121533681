import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from "react-router-dom";
import FileManagerPage from "../pages/FileManagerPage";
import ContactUsPage from "../pages/ContactUsPage";
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import { components } from './Auth';

const Routes = () => {
  return (
      <Router>
      <Switch>
        <Route path="/" element={<FileManagerPage />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      </Switch>
    </Router>
  );
};

export default Routes;
