import React from "react";
import {
  uploadData,
  isCancelError,
  getUrl,
  UploadDataWithPathOutput,
  downloadData,
  getProperties,
  list,
  ListPaginateWithPathOutput,
  copy,
  remove,
} from "aws-amplify/storage";
import { IFile } from "../@types/global";

  // Extract file name and extension using string manipulation
export const fileName = (filePath: string) => filePath.substring(filePath.lastIndexOf("/") + 1);
export const fileExtension = (fileName: string) => fileName.substring(fileName.lastIndexOf("."));
export const fileNameWithoutExtension = (fileName: string) => fileName.substring(0, fileName.lastIndexOf("."));

/**
 * Get a presigned URL of a stored file and expiration of URL.
 * @param path: string - for protected routes -> ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
 * @desc for generating temparary links to a file.
 * @methods [ url, expiresAt ]
 * @usage <a href="{signedURL.url.toString()}" target="_blank" rel="noreferrer">{fileName}</a>
 */
export const generateLink = async (path: string) => {
  try {
    const result = await getUrl({
      path: path,
      options: {
        validateObjectExistence: true, // Check if object exists before creating a URL
        expiresIn: 60, // in seconds. default = 900 (15 minutes) and max = 3600 (1 hour)
        useAccelerateEndpoint: true, // Whether to use accelerate endpoint
      },
    });
    return { url: result.url, expiresAt: result.expiresAt };
  } catch (error) {
    console.error(error);
  }
};

export const cancelFileTask = async (
  task: UploadDataWithPathOutput,
  onCancel: () => void
) => {
  task.cancel();
  try {
    return await task.result;
  } catch (error) {
    if (isCancelError(error)) {
      // Handle error thrown by task cancellation
      onCancel();
    }
  }
};

/**
 * You can get file properties and metadata without downloading the file
 * @param endpoint 
 * @param identityId 
 * @returns \{
              path: '',
              contentType: '',
              contentLength: ,
              eTag: '',
              lastModified: '',
              metadata: {}
            }
 */
export const getInfo = async (endpoint: string, identityId?: string) => {
  const path = identityId ? `protected/${identityId}${endpoint}` : endpoint;
  try {
    const result = await getProperties({
      path: path,
    });
    console.log("File Properties ", result);
    return result;
  } catch (error) {
    console.log("Error ", error);
  }
};

/**
 * The uploadData method uploads files into Amazon S3.
 * @param e
 * @param formData
 * @returns
 */
export const newUpload = async (
  e: React.ChangeEvent<HTMLInputElement>,
  returnProgress?: ( transferredBytes: number, totalBytes: number ) => number,
  formData?: Record<string, string> | undefined
) => {
  try {
    if (e.target.files) {
      const file = e.target.files[0];
      const result = uploadData({
        path: file.name,
        data: file,
        options: {
          //metadata: formData, // (map<String>) A map of metadata to store with the object in S3.
          onProgress: ({ transferredBytes, totalBytes }) => {
            if (totalBytes) {
              console.log(
                `Upload progress ${Math.round(
                  (transferredBytes / totalBytes) * 100
                )} %`
              );
              returnProgress && returnProgress(transferredBytes, totalBytes);
            }
          },
        },
      });
      return result;
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * Download a file to in-memory buffer.
 * @param endpoint path endpoint
 * @param identityId for a protected
 * @param onProgress
 * @returns
 */
export const newDownload = async (
  path: string,
  onProgress?: () => number
) => {
  // Download a file from s3 bucket
  const { body, eTag } = await downloadData({
    path: path,
    options: {
      onProgress,
    },
  }).result;
  return { body, eTag };
};

/**
 * You can either list all objects uploaded under a given path or opt to receive a paginated list of objects.
 * @param endpoint
 * @param identityId
 * @param all
 * @returns
 */
export const listStorage = async (path: string) => {
  const filesystem = {};
  const result = await list({
    path: path,
    options: { listAll: true },
  });
  const add = (source: string, target: any, item: any) => {
    const elements = source.split("/");
    const element = elements.shift();
    if (!element) return; // blank
    target[element] = target[element] || { __data: item }; // element;
    if (elements.length) {
      target[element] =
        typeof target[element] === "object" ? target[element] : {};
      add(elements.join("/"), target[element], item);
    }
  };
  try {
    result.items.map((item) => ({
      path: item.path,
      eTag: item.eTag,
      lastModified: item.lastModified,
      size: item.size,
    }));
    result.items.forEach((item) => add(item.path, filesystem, item));
    return filesystem;
  } catch (error) {
    console.log(error);
  }
};

/**
 * If you need to list all the folders, you'll have to parse them accordingly to get an authoritative list of files and folders
 * @param response
 * @returns
 */
export const listFiles = async (path: string) => {
  let files: IFile[] = [];
  let folders = new Set();
  const result = await list({
    path: path,
    options: { listAll: true },
  });
  try {
    result.items.forEach((item) => {
      if (item.size) {
        files.push(item);
        // sometimes files declare a folder with a / within then
        let possibleFolder = item.path
          .split("/")
          .slice(0, -1)
          .join("/");
        if (possibleFolder) folders.add(possibleFolder);
      } else {
        folders.add(item.path);
      }
    });
    return { files, folders };
  } catch {}
};

/**
 * Files can only be deleted for the current user. Deletion capabilities do not extend to files associated with other users.
 * @param endpoint
 * @param identityId
 * @param priProPub
 */
export const softDelete = async (path: string) => {
  try {
    return await remove({ path });
  } catch (error) {
    console.log("Error ", error);
  }
};

/**
 * The copy API duplicates an existing file to a designated path and returns an object {path: 'destPath'} upon successful completion.
 * @param endpointFrom
 * @param endpointTo
 * @param identityIdFrom
 * @param identityIdTo
 * @param priProPubFrom
 * @param priProPubTo
 */
export 
const copyFile = async (source: string, destination: string) => {
  try {
    const response = await copy({
      source: {
        path: source,
      },
      destination: {
        path: destination,
      },
    });
    return list({ path: destination });
  } catch (error) {
    console.error("Error", error);
  }
};
