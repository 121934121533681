import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Box from "../styles/Box";
import Text, { Heading } from "../styles/Text";
import { getUrl, list, remove } from "aws-amplify/storage";
import { IFile } from "../@types/global";
import { StorageManager } from "@aws-amplify/ui-react-storage";
import Tabs, { Tab } from "../components/Tabs";
import FileList from "../components/FileList";

const FileManagerPage: React.FC = () => {
  const { user, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);
  
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [deliveries, setDeliveries] = React.useState<IFile[]>([]);
  const ref = React.useRef(null);

  async function fetchFiles() {
    try {
      const listOfFiles = await list({
        path:
          authStatus === "authenticated"
            ? ({ identityId }) =>
                `protected/${identityId}/drops/`
            : "public/",
      });
      const deliveryList = await list({
        path:
          authStatus === "authenticated"
            ? ({ identityId }) =>
                `protected/${identityId}/deliveries/`
            : "public/",
      });
      setFiles(listOfFiles.items);
      setDeliveries(deliveryList.items);
    } catch (err) {
      console.error("Error listing files:", err);
    } finally {
      console.log("files: ", files);
    }
  };

  const handleFileDelete = async (path: string) => {
    try {
      await remove({ path });
      await fetchFiles();
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  };

  const handleFileDownload = async (path: string) => {
    try {
      const link = await getUrl({ path });
      window.open(link.url, "_blank");
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  };

  return (
    <Box padding={["1rem", "1rem", "3rem 5rem", "3rem 5rem"]}>
      <Tabs>
        <Tab label={"Manage"}>
        <Box padding={"3rem"}>
            <Box width={1}>
              <StorageManager
                acceptedFileTypes={[
                  "image/*",
                  "audio/*",
                  "video/*",
                  "text/*",
                  "application/*",
                ]}
                path={authStatus === "authenticated"
                ? ({ identityId }) =>
                    `protected/${identityId}/drops/`
                : "public/"}
                maxFileCount={100}
                //maxFileSize={1000000}
                onUploadSuccess={fetchFiles}
                onFileRemove={fetchFiles}
                ref={ref}
                isResumable
              />
            </Box>
          </Box>
          <Box padding={"3rem"}>
            <FileList
              files={files}
              onDownload={handleFileDownload}
              onDelete={handleFileDelete}
            />
          </Box>
        </Tab>
        <Tab label={"Pick-Up"}>
          <Box padding={"3rem"}>
            <Box width={1}>
              <FileList
                files={deliveries}
                onDownload={handleFileDownload}
                onDelete={handleFileDelete}
              />
            </Box>
          </Box>
        </Tab>
      </Tabs>
    </Box>
  );
};

export default FileManagerPage;
