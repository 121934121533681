import React from "react";
import useAxios from "../hooks/useAxios";
import useForm, { Form, Label, Line, Textarea } from "../hooks/useForm";
import Text, { Title, Button, Link } from "../styles/Text";
import Box from "../styles/Box";
import { theme } from "../styles/theme";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styled from "styled-components";

const Submit = styled(Button)`
  color: ${(props) => props.theme.colors.white};
  text-shadow: 0 0 0.125rem ${theme.colors.dark};
  background-color: ${(props) => props.theme.colors.dark};
  margin: 1rem auto;
  &:hover {
    color: ${(props) => props.theme.colors.gold};
  }
`;
// Component
const ContactUsPage: React.FC = () => {
  const { user, authStatus } = useAuthenticator((context) => [
    context.user,
    context.authStatus,
  ]);

  const initData = {
    id: user.userId,
    email: user.signInDetails?.loginId,
    subject: "",
    message: "",
    privacy: false,
    terms: false,
  };

  const rules = {
    subject: (value: string) => {
      if (!value.trim()) return "Subject is required.";
    },
    message: (value: string) => {
      if (!value.trim()) return "Message is required.";
    },
    privacy: (value: string) => {
      if (!value) return "Please read our privacy policy.";
    },
    terms: (value: string) => {
      if (!value) return "Please read our terms and conditions.";
    },
  };

  const url =
    "https://mp6hvovha1.execute-api.us-east-1.amazonaws.com/v1" +
    "/form/message";
  const key = process.env.REACT_APP_API_KEY;

  const { values, errors, handleBlur, handleChange, handleSubmit } = useForm(initData, rules);

  const { post } = useAxios(url, key as string);

  const onSubmit = async () => {
    await post(values);
  };

  return (
    <Box
      maxWidth={theme.width.lg}
      width={1}
      margin={"2.5rem auto"}
      marginTop={"8rem"}
    >
      <Title textAlign={"center"}>Send Us A Message</Title>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => handleSubmit(e, onSubmit)}>
        <Line
         marginBottom={0}
          name="subject"
          placeholder="Subject..."
          value={values.subject as string}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Text color={theme.colors.error}>{errors.subject}</Text>
        <Textarea
         marginBottom={0}
          name="message"
          placeholder="Message..."
          value={values.message as string}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={8}
        />
        <Text color={theme.colors.error}>{errors.message}</Text>
        <Label checkbox>
          <Line
           marginBottom={0}
            type="checkbox"
            name="privacy"
            checked={values.privacy as boolean}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          I have read the&nbsp;
          <Link to={"/privacy-policy"} target={"_blank"}>
            privacy policy
          </Link>
        </Label>
        <Text color={theme.colors.error}>{errors.privacy}</Text>
        <Label checkbox>
          <Line
           marginBottom={0}
            type="checkbox"
            name="terms"
            checked={values.terms as boolean}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          I have read and accepted the&nbsp;
          <Link to={"/terms-and-conditions"} target={"_blank"}>
            terms and conditions
          </Link>
        </Label>
        <Text color={theme.colors.error}>{errors.terms}</Text>
        <Submit type="submit">Submit</Submit>
      </Form>
    </Box>
  );
};

export default ContactUsPage;
