import React from "react";
import Box from "../styles/Box";
import Text, { Heading, SubHeading } from "../styles/Text";

interface IProps {}

const TermsAndConditionsPage: React.FC<IProps> = () => {
  const company = "Alkenox™️";
  const arbitrationInstitution = "the American Arbitration Association";

  return (
    <>
      <Box padding={["5rem 2.5rem", "5rem 10rem"]}>
        <Heading>Terms and Conditions</Heading>

        <Box marginY={"1rem"}>
          <SubHeading>1. Introduction</SubHeading>
          <Text>
            1.1 These Terms and Conditions govern the use of services provided
            by {company}, a branded service from LeeVan Enterprises, a limited
            liability company registered in United States of America
            {/** , with registered office at [Your Address] */}.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>2. Scope of Services</SubHeading>
          <Text>
            2.1 {company} offers post-production, music mixing, mastering, and
            related services as described on the website and agreed upon with
            the client.
          </Text>

          <Text>
            2.2 Clients agree to provide necessary materials, files, and
            information required for the provision of services in a timely
            manner.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>3. Pricing and Payment</SubHeading>
          <Text>
            3.1 Pricing for services is determined based on the scope,
            complexity, and duration of the project. Quotes provided by{" "}
            {company} are valid for a period of 30 days from the date of
            issuance.
          </Text>

          <Text>
            3.2 Payment terms are outlined in the invoice provided to the
            client. Payment is due 10 business days from the date of invoice
            issuance unless otherwise specified.
          </Text>

          <Text>
            3.3 {company} reserves the right to suspend services or withhold
            deliverables until payment is received in full.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>4. Intellectual Property</SubHeading>
          <Text>
            4.1 All intellectual property rights, including copyrights,
            trademarks, and other proprietary rights, in deliverables produced
            by {company} remain the property of {company} unless otherwise
            agreed upon in writing.
          </Text>

          <Text>
            4.2 Clients retain ownership of their original content and materials
            provided to {company} for the purpose of service provision.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>5. Confidentiality</SubHeading>
          <Text>
            5.1 {company} agrees to keep confidential all proprietary and
            sensitive information shared by the client, including but not
            limited to project details, files, and communications.
          </Text>

          <Text>
            5.2 Clients agree to keep confidential any proprietary or sensitive
            information shared by {company} in the course of service provision.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>6. Limitation of Liability</SubHeading>
          <Text>
            6.1 {company} shall not be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection
            with the provision of services, including but not limited to loss of
            profits, data, or business opportunities.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>7. Termination</SubHeading>
          <Text>
            7.1 Either party may terminate the agreement upon 10 business days
            written notice if the other party breaches any material provision of
            these Terms and Conditions.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>8. Governing Law and Dispute Resolution</SubHeading>
          <Text>
            8.1 These Terms and Conditions shall be governed by and construed in
            accordance with the laws of United States of America.
          </Text>

          <Text>
            8.2 Any disputes arising out of or in connection with these Terms
            and Conditions shall be attempted to be resolved through parties
            agree to attempt to resolve any disputes through mediation
            administered by a Mediation Institution of our choice. If the
            dispute remains unresolved then the dispute can move forward to be
            resolved through arbitration in accordance with the rules of{" "}
            {arbitrationInstitution}.
          </Text>
        </Box>

        <Box marginY={"1rem"}>
          <SubHeading>9. Miscellaneous</SubHeading>
          <Text>
            9.1 These Terms and Conditions constitute the entire agreement
            between {company} and the client and supersede all prior agreements
            and understandings, whether oral or written.
          </Text>

          <Text>
            9.2 {company} reserves the right to update or modify these Terms and
            Conditions at any time. Clients will be notified of any changes via
            email or through the website.
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default TermsAndConditionsPage;
