import styled from "styled-components";
import {
  space,
  layout,
  flexbox,
  grid,
  background,
  border,
  position,
} from "styled-system";
import { StyledSystemTypes } from "../@types/global";

const Image = styled.img<StyledSystemTypes>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
`;

Image.defaultProps = {}

const Break = styled.hr<StyledSystemTypes>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
`;

Image.defaultProps = {}

const Box = styled.div<StyledSystemTypes>`
  ${space}
  ${layout}
  ${border}
  ${position}
  ${flexbox}
  ${grid}
  ${background}
`;

Box.defaultProps = {
  maxWidth: '60rem',
  marginX: `auto`
}

export default Box;
export {
  Image,
  Break
}